// Core
import React from "react";
import { NavLink, useLocation } from "react-router-dom";

// Style
import { MenuItem, MenuList, SideBarWrapper } from "./styles";
import { Props } from "./types";
import { Label } from "Assets/Styles/globalStyles";

// Types
import { FontSizes } from "Utils/enums";

// Components
import Logo from "Assets/Images/logo_white.svg";

// Others
import { Box } from "@rebass/grid";
import { sideBarMenu } from "routes/helpers";
import { INITIAL_ROUTE } from "config/constants";

const SideBar: React.FC<Props> = () => {
  const location = useLocation();
  const currentPath = location.pathname;

  return (
    <SideBarWrapper>
      <Box>
        <NavLink to={INITIAL_ROUTE}>
          <img alt="logo" src={Logo} />
        </NavLink>
      </Box>
      <MenuList>
        {sideBarMenu.map(({ path, label, icon, activeFor }) => {
          return (
            <MenuItem
              className={`${
                currentPath.includes(activeFor as string) ? "active" : ""
              }`}
              to={path}
              end
              key={label}
            >
              <Box>{icon}</Box>
              <Label pointer fontSize={FontSizes.sidebar}>
                {label}
              </Label>
            </MenuItem>
          );
        })}
      </MenuList>
    </SideBarWrapper>
  );
};

export default SideBar;
