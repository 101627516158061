// Core
import { Box, Flex } from "@rebass/grid";
import { Grid, Label } from "Assets/Styles/globalStyles";
import { Collapse } from "antd";
import Button from "Components/Button";
import InputField from "Components/InputField";
import { FontSizes, FontWeight } from "Utils/enums";
import React, { useState } from "react";
import {
  SearchOutlined,
  FilterOutlined,
  PlusCircleOutlined,
} from "@ant-design/icons";
import { FilterWrapper, HeaderWrapper } from "./styles";
import dayjs from "dayjs";

// Components

// Types
import { Props } from "./types";
import { colors } from "Assets/Styles/colors";
import { DatePicker, Switch } from "antd";
import { formatSSN, getFilteredObject } from "Utils/helpers";
import Select from "Components/Select";

const { Panel } = Collapse;

const PageHeader: React.FC<Props> = (props: Props) => {
  const {
    formFields,
    title,
    addNewBtnLabel,
    handleAdd,
    fetchData,
    loading,
    noFilter,
    hideAdd,
    hideTitle,
    singleRow,
  } = props;
  const [formData, setFormData] = useState<any>({});
  const [filterOpen, setFilterOpen] = useState(false);

  const onchangeHandler = (key: string) => (value: string) => {
    setFormData({
      ...formData,
      [key]: key === "ssn" ? formatSSN(value) : value,
    });
  };

  const onChangeDate = (key: string) => (date: any, dateString: any) => {
    setFormData({
      ...formData,
      [key]: dateString,
    });
  };

  const onChangeSwitch = (key: string) => (checked: boolean) => {
    setFormData({
      ...formData,
      [key]: checked,
    });
  };

  const handleToggleFilter = () => setFilterOpen((prev) => !prev);

  const handleSubmit = () => {
    fetchData(getFilteredObject(formData));
  };

  const handleClearForm = () => {
    setFormData({});
  };

  const handleKeyPress = (event: {
    key?: any;
    preventDefault?: () => void;
  }) => {
    if (event.key === "Enter") {
      event.preventDefault?.();
      handleSubmit();
    }
  };

  const { searchQuery } = formData;
  const valuesCount = Object.keys(formData).filter(
    (key) => key !== "searchQuery" && formData[key]
  ).length;

  return (
    <form onSubmit={handleSubmit} onKeyPress={handleKeyPress}>
      {!hideTitle && (
        <Label fontWeight={FontWeight.medium} fontSize={FontSizes.h3}>
          {title}
        </Label>
      )}
      <Flex marginTop={15} justifyContent="space-between" alignItems="center">
        <HeaderWrapper>
          <InputField
            noLabel
            placeholder="Search"
            onChange={onchangeHandler("searchQuery")}
            value={searchQuery}
            label=""
            isMandatory
          />
          <Button
            disabled={loading}
            icon={<SearchOutlined />}
            onClickHandler={handleSubmit}
          />
          {!noFilter && (
            <Button
              icon={<FilterOutlined />}
              onClickHandler={handleToggleFilter}
              type="dashed"
              tagCount={!filterOpen && valuesCount}
              label={filterOpen ? "Hide Filter" : "Filter"}
            />
          )}
        </HeaderWrapper>
        {!hideAdd && (
          <Button
            icon={<PlusCircleOutlined />}
            onClickHandler={handleAdd}
            label={addNewBtnLabel}
          />
        )}
      </Flex>
      <Collapse
        className="no-panel-collapse"
        accordion
        activeKey={filterOpen ? ["1"] : []}
      >
        <Panel header="Panel 1" key="1">
          <FilterWrapper>
            <Grid col={4} gap={12}>
              {formFields?.map((item: any) => {
                const { type, key, label, options } = item;
                if (type === "CB") {
                  return (
                    <Flex key={key} alignItems="center" marginTop={20}>
                      <Switch
                        checked={formData[key]}
                        onChange={onChangeSwitch(key)}
                      />
                      <Label
                        marginLeft={5}
                        fontSize={FontSizes.floatingLabel}
                        color={colors.primaryText}
                        fontWeight={FontWeight.default}
                      >
                        {label}
                      </Label>
                    </Flex>
                  );
                }
                if (type === "SB") {
                  return (
                    <Select
                      label={label}
                      placeholder="Enter"
                      onChangeHandler={onchangeHandler(key)}
                      options={options}
                      value={formData[key]}
                    />
                  );
                }
                if (type === "DS") {
                  return (
                    <Box>
                      <Label
                        marginBottom={5}
                        fontSize={FontSizes.floatingLabel}
                        color={colors.primaryText}
                        fontWeight={FontWeight.default}
                      >
                        {label}
                      </Label>
                      <DatePicker
                        format="MM/DD/YYYY"
                        value={formData[key] ? dayjs(formData[key]) : null}
                        // value={formData[key]}
                        onChange={onChangeDate(key)}
                      />
                    </Box>
                  );
                }
                return (
                  <InputField
                    placeholder="Enter"
                    onChange={onchangeHandler(key)}
                    value={formData[key]}
                    label={label}
                  />
                );
              })}
              {!singleRow && <Box />}
              <Flex
                style={{ gap: "8px" }}
                alignItems="end"
                justifyContent="end"
              >
                <Button
                  label="Reset"
                  type="dashed"
                  onClickHandler={handleClearForm}
                />
                <Button
                  // loading={loading}
                  disabled={loading}
                  onClickHandler={handleSubmit}
                  label={"Submit"}
                />
              </Flex>
            </Grid>
          </FilterWrapper>
        </Panel>
      </Collapse>
    </form>
  );
};

export default PageHeader;
