import { colors } from "Assets/Styles/colors";
import styled from "styled-components";

export const HeaderWrapper = styled.div`
   display: flex;
   align-items: flex-start;
   
   .ant-input {
    width: 250px !important;
   }

   .ant-btn-icon-only {
    width: 40px;
    margin-left: 8px;
    margin-right: 12px;
    padding: 9px 13px;
    
    .anticon {
      font-size: 0.8rem;
    }
   }
  `;


export const FilterWrapper = styled.div`
  margin-top: 15px;
  border-radius: 8px;
  background: white;
  padding: 20px;
  border: 1px solid ${colors.grey4};
`;
