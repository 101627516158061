import React from "react";
import Spin from "antd/es/spin";
import { Props } from "./types";
import { LoaderWrapper } from "./styles";

const Loader: React.FC<Props> = (props: Props) => {
  const { fullscreen, size = 'default', isChunkLoader } = props;

  return (
    <LoaderWrapper isChunkLoader={isChunkLoader} alignItems="center" justifyContent="center">
      <Spin fullscreen={fullscreen} size={size} />
    </LoaderWrapper>
  );
};

export default Loader;
