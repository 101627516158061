import React from "react";
import Select from "antd/es/select";

// Style
import { Props } from "./types";
import { Label } from "Assets/Styles/globalStyles";
import { FontSizes, FontWeight } from "Utils/enums";
import { colors } from "Assets/Styles/colors";
import { ErrorLabel } from "Components/InputField/styles";
import { Box } from "@rebass/grid";

const CustomSelect: React.FC<Props> = (props: Props) => {
  const {
    options,
    defaultValue,
    onChangeHandler,
    value,
    disabled,
    label,
    placeholder,
    isMandatory,
    error,
    viewMode,
  } = props;

  return (
    <Box>
      <Label
        marginBottom={viewMode ? 0 : 5}
        fontSize={FontSizes.floatingLabel}
        color={colors.primaryText}
        fontWeight={FontWeight.default}
      >
        {label} {isMandatory && !viewMode && <span>*</span>}
      </Label>
      {viewMode ? (
        <>
          <Label
            marginBottom={5}
            fontSize={FontSizes.input}
            color={colors.primaryText}
            fontWeight={FontWeight.bold}
          >
            {value || "-"}
          </Label>
        </>
      ) : (
        <>
          <Select
            value={value}
            showSearch
            optionFilterProp="label"
            placeholder={label || placeholder}
            disabled={disabled}
            defaultValue={defaultValue}
            onChange={onChangeHandler}
            options={options}
            status={error ? "error" : ""}
          />
          {!!error && (
            <ErrorLabel
              marginTop={1}
              fontSize={FontSizes.error}
              color={colors.error}
              fontWeight={FontWeight.light}
            >
              {error}
            </ErrorLabel>
          )}
        </>
      )}
    </Box>
  );
};

export default CustomSelect;
