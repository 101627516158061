import React from "react";
import { Box, Flex } from "@rebass/grid";
import { UserOutlined } from "@ant-design/icons";
import { Avatar, Tag } from "antd";

// Styles
import { Label } from "Assets/Styles/globalStyles";

// Types
import { FontSizes } from "Utils/enums";
import { S3_URL } from "config/constants";
import { colors } from "Assets/Styles/colors";

type Props = {
  url?: string;
  label: string;
  isPointer?: boolean;
  tag?: string;
  email?: string;
};

const UserHead: React.FC<Props> = (props: Props) => {
  const { url = null, label, tag, isPointer, email } = props;

  return (
    <Flex alignItems="center" style={{minWidth: "180px"}}>
      <Avatar src={url ? `${S3_URL}${url}` : null} icon={<UserOutlined />} />
      <Box marginLeft="5px" style={{ flex: 1 }}>
        <Label pointer={isPointer} fontSize={FontSizes.table}>{label}</Label>
        {email && <Label pointer={isPointer} marginTop={-2} fontSize={FontSizes.floatingLabel} color={colors.secondaryText}>{email}</Label>}
        {tag && (
          <Tag bordered={false} style={{ marginTop: "3px" }} color="processing">
            {tag}
          </Tag>
        )}
      </Box>
    </Flex>
  );
};

export default UserHead;
