import styled, { css } from 'styled-components';
import { Flex } from '@rebass/grid';

interface LoaderWrapperProps {
    isChunkLoader?: boolean;
}

export const LoaderWrapper = styled(Flex) <LoaderWrapperProps>`
  ${({ isChunkLoader }) =>
        isChunkLoader &&
        css`
        padding: 50px;
    `}
`;
