/* eslint-disable react-hooks/exhaustive-deps */
// Core
import React, { useEffect, useRef, useState } from "react";
import {
  SendOutlined,
  WarningOutlined,
} from "@ant-design/icons";

// Styles
import { Box, Flex } from "@rebass/grid";
import { colors } from "Assets/Styles/colors";

// Components
import { Label } from "Assets/Styles/globalStyles";
import PageHeader from "Components/PageHeader";
import Table from "Components/Table";

// Types
import { FontSizes, FontWeight } from "Utils/enums";

// Others
import {
  formFields,
  getColumns,
  getCurrentOrgRoles,
  getFilteredData,
  hcpOptions,
  officeOptions,
} from "./helpers";
import { fetchData } from "Utils/fetch";
import { restAPIs } from "Utils/restAPIs";
import Modal from "antd/es/modal/Modal";
import Button from "Components/Button";
import InputField from "Components/InputField";
import { pluralize, validateEmail } from "Utils/helpers";
import Checkbox from "antd/es/checkbox";
import { useUserDetails } from "hooks/useUserDetails";
import { ErrorLabel } from "Components/InputField/styles";
import { useDebounce } from "hooks/useDebounceSearch";
import { PlanWrapper, StatusTag } from "./styles";
import { useNavigate } from "react-router-dom";
import { useOrganization } from "hooks/useOrganization";

type Props = {
  isHCP?: boolean;
  patientId?: string;
};

const MyOffice: React.FC<Props> = (props: Props) => {
  const { userLicenseRemaining } = useUserDetails();
  const { updateOrganizationData } = useOrganization();
  const { isHCP, patientId } = props;
  const navigate = useNavigate();
  const STATE = {
    email: "",
    roles: isHCP ? ["HCPWithFullAccess"] : ["Doctor"],
  };
  const [open, setOpen] = useState(false);
  const [errors, setErrors] = useState<any>({});
  const [data, setData] = useState<any>([]);
  const [formData, setFormData] = useState<any>(STATE);
  const dataRef = useRef<any[]>([]);
  const [loading, setLoading] = useState(true);
  const [userLoading, setUserLoading] = useState(false);
  const [apiLoading, setApiLoading] = useState(false);
  const [userList, setUserList] = useState<any[]>([]);
  const { email, roles, isEdit } = formData;

  const context = isHCP ? "HCP" : "Member";

  const handleCheckboxChange = (checkedValues: any) => {
    const updatedValues = roles.includes("Doctor")
      ? [...checkedValues, "Doctor"]
      : checkedValues;

    setFormData({
      ...formData,
      roles: updatedValues,
    });
  };

  const handleDoctorCheckboxChange = (e: any) => {
    const checked = e.target.checked;
    const doctorRole = "Doctor";
    setFormData({
      ...formData,
      roles: checked
        ? [
            doctorRole,
            ...formData.roles.filter((role: any) => role !== doctorRole),
          ]
        : formData.roles.filter((role: any) => role !== doctorRole),
    });
  };

  const { organizationId, userDetails, isAdmin, isPaidPlan } = useUserDetails();
  const debouncedEmail = useDebounce(email, 500);

  const handleEmailCheck = async () => {
    setUserLoading(true);
    const res = await fetchData(
      restAPIs.getOrgUsers({
        page: 1,
        pageSize: 20,
        searchQuery: debouncedEmail,
        currentOrganizationId: organizationId,
      })
    );
    if (res.data) {
      const filteredOptions = res.data?.results.map((item: any) => {
        return {
          label: `${item?.personalInformation.firstName} ${item?.personalInformation.lastName}`,
          value: item.email,
          url: item.personalInformation?.profilePicKey,
        };
      });
      setUserList(filteredOptions);
    }
    setUserLoading(false);
  };

  useEffect(() => {
    if (debouncedEmail) handleEmailCheck();
  }, [debouncedEmail]);

  const validateForm = (): boolean => {
    const newError = {
      email: validateEmail(email) ? "" : "Please enter a valid email address",
      roles: roles.length
        ? ""
        : `Please select any ${isHCP ? "access level" : "role"}`,
    };
    setErrors(newError);
    return !Object.values(newError).some((item) => !!item);
  };

  const handleFilterChange = (formData: any) => {
    setData(getFilteredData(dataRef.current, formData));
  };

  const fetchTableData = async () => {
    setLoading(true);
    let inviteReq: any = "";
    let userReq: any = "";
    if (isHCP) {
      inviteReq = restAPIs.getHcpInviteList(patientId as string);
      userReq = restAPIs.getHcpList(patientId as string);
    } else {
      inviteReq = restAPIs.getInviteList(organizationId);
      userReq = restAPIs.getUserList(organizationId);
    }
    const [inviteRes, userRes] = await Promise.all([
      fetchData(inviteReq),
      fetchData(userReq),
    ]);
    const data = [...inviteRes?.data, ...userRes?.data];
    const _data = data
      .slice()
      .sort((a, b) => a.createdAt?.localeCompare(b?.createdAt));
    setData(_data);
    dataRef.current = _data;
    setLoading(false);
  };

  useEffect(() => {
    fetchTableData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleDelete = (item: any) => async () => {
    let res = {};
    if (item?.status === "Invited") {
      res = await fetchData(restAPIs.deleteInvite(item?._id));
    } else if (isHCP) {
      res = await fetchData(restAPIs.deleteHcp(patientId as string, item?._id));
    } else {
      res = await fetchData(restAPIs.deleteTeam(item?._id, organizationId));
      await updateOrganizationData();
    }
    if (res) {
      fetchTableData();
    }
  };

  const handleAddOrEditMember = (_data?: any) => () => {
    const itemEmail = _data.email || _data.invitedEmail;
    const currentOrgRoles = getCurrentOrgRoles(_data, organizationId);
    const roleItem = _data.roles || currentOrgRoles;
    setFormData({
      ..._data,
      isEdit: !!_data?._id,
      email: itemEmail?.trim(),
      roles: roleItem,
    });
    setOpen((prev) => !prev);
  };

  const handleOk = async () => {
    if (!validateForm()) return null;
    setApiLoading(true);
    const payload = { ...formData, email: formData?.email?.trim() };
    let res: any = {};
    if (payload.isEdit) {
      if (isHCP) {
        res = await fetchData(
          restAPIs.updateHcpRoles(
            { roles: payload?.roles },
            patientId as string,
            payload?._id
          )
        );
      } else {
        res = await fetchData(
          restAPIs.updateUserRoles(
            { roles: payload?.roles },
            payload?._id,
            organizationId
          )
        );
        if (res) {
          updateOrganizationData();
        }
      }
    } else {
      delete payload.isEdit;
      if (isHCP) {
        res = await fetchData(
          restAPIs.inviteHCP(payload, organizationId, patientId as string)
        );
      } else {
        res = await fetchData(restAPIs.inviteUser(payload, organizationId));
      }
    }
    if (res) {
      fetchTableData();
      handleCancel();
    }
    setApiLoading(false);
  };

  const handleCancel = () => {
    setFormData(STATE);
    setErrors({});
    setUserList([]);
    setOpen(false);
  };

  const onchangeHandler = (key: string) => (value: string) => {
    setFormData({
      ...formData,
      [key]: value,
    });
  };

  const tableCols = getColumns(
    handleAddOrEditMember,
    handleDelete,
    userDetails?._id,
    isAdmin,
    organizationId,
    context
  );

  const hideAddAction = (!isAdmin && !isHCP) || !isPaidPlan;

  if (hideAddAction) {
    tableCols.pop();
  }

  const planStatus = userLicenseRemaining === 0 ? "warning" : "success";
  const isDoctor = roles?.includes("Doctor");

  return (
    <>
      <PageHeader
        title={isHCP ? "Health Care Provider (HCP)" : "My Office"}
        loading={loading}
        addNewBtnLabel={`Add New ${context}`}
        handleAdd={handleAddOrEditMember(STATE)}
        formFields={formFields}
        fetchData={handleFilterChange}
        noFilter
        hideAdd={hideAddAction}
      />
      <Flex marginTop={20} marginBottom={15}>
        <Label fontWeight={FontWeight.medium} fontSize={FontSizes.h6}>
          {data?.length || 0}
        </Label>
        <Label
          marginLeft={5}
          fontWeight={FontWeight.medium}
          color={colors.grey1}
          fontSize={FontSizes.h6}
        >
          {pluralize(data?.length, context)}
        </Label>
      </Flex>
      <Box>
        <Table
          noPagination
          loading={loading}
          dataSource={data}
          columns={tableCols}
        />
      </Box>
      {open && (
        <Modal
          title={`${isEdit ? "Edit" : "Add New"} ${context}`}
          width={isHCP ? 600 : 700}
          open={open}
          onOk={handleOk}
          onCancel={handleCancel}
          footer={[
            <Button
              type="dashed"
              label="Cancel"
              onClickHandler={handleCancel}
            />,
            <Button
              loading={apiLoading}
              disabled={!userLicenseRemaining && !isHCP && isDoctor}
              label={`${isEdit ? "Update" : "Send"} Invite`}
              icon={<SendOutlined />}
              onClickHandler={handleOk}
            />,
          ]}
        >
          <InputField
            onChange={onchangeHandler("email")}
            placeholder="Email"
            value={email}
            disabled={isEdit}
            label={`Enter team ${context} email address`}
            error={errors.email}
            isMandatory
            isAutoComplete
            options={userList}
            optionLoading={userLoading}
          />
          <Label marginTop={20} marginBottom={10} fontSize={FontSizes.h4}>
            {isHCP ? "Select access level" : "Select Team roles"}
          </Label>
          {isHCP ? (
            <Checkbox.Group
              options={hcpOptions.map((option) => ({
                label: (
                  <span>
                    {option.label}
                    <br />
                    <small>{option.additionalText}</small>
                  </span>
                ),
                value: option.value,
              }))}
              onChange={handleCheckboxChange}
              value={roles}
            />
          ) : (
            <>
              <PlanWrapper status={planStatus}>
                <Box style={{ maxWidth: "70%" }}>
                  <StatusTag status={planStatus}>
                    {!userLicenseRemaining ? (
                      <>
                        <WarningOutlined style={{ color: colors.error4 }} /> No
                        pending licenses
                      </>
                    ) : (
                      <>{userLicenseRemaining} Licenses left</>
                    )}
                  </StatusTag>
                  <Label
                    marginTop={15}
                    marginBottom={15}
                    fontSize={FontSizes.small}
                  >
                    {!userLicenseRemaining
                      ? `Your Workspace has been reaching its maximum number of
                  members allowed. Purchase more licenses and add a new
                  member.`
                      : `Great news! Your Workspace currently has available licenses to accommodate new members. Keep expanding your team and making the most of your resources!`}
                  </Label>
                  <Checkbox
                    value="Doctor"
                    checked={roles.includes("Doctor")}
                    onChange={handleDoctorCheckboxChange}
                  >
                    <span>
                      Doctor
                      <br />
                      <Label color={colors.grey6} fontSize={FontSizes.table}>
                        Dashboard, Calendar, Appointments, Reports, Patients
                      </Label>
                    </span>
                  </Checkbox>
                </Box>
                {!userLicenseRemaining && (
                  <Button
                    onClickHandler={() => navigate("/update-subscription-plan")}
                    label="Buy Now"
                  />
                )}
              </PlanWrapper>
              <Label
                marginTop={20}
                marginBottom={15}
                fontWeight={FontWeight.medium}
                fontSize={FontSizes.input}
              >
                Other Roles
              </Label>
              <Checkbox.Group
                options={officeOptions.map((option) => ({
                  label: (
                    <span>
                      {option.label}
                      <br />
                      <small>{option.additionalText}</small>
                    </span>
                  ),
                  value: option.value,
                }))}
                onChange={handleCheckboxChange}
                value={roles}
              />
            </>
          )}
          {errors.roles && (
            <ErrorLabel
              marginLeft={25}
              marginTop={5}
              fontSize={FontSizes.error}
              color={colors.error}
              fontWeight={FontWeight.light}
            >
              {errors.roles}
            </ErrorLabel>
          )}
        </Modal>
      )}
    </>
  );
};

export default MyOffice;
