// Core
import React from "react";
import { NavLink } from "react-router-dom";

// Components
import Banner from "Assets/Images/login.svg";
import Logo from "Assets/Images/logo.svg";

// Styles
import { Box } from "@rebass/grid";
import { Label } from "Assets/Styles/globalStyles";
import { Container, ImgBox, FormContainer } from "./styles";
import { colors } from "Assets/Styles/colors";

// Types
import { Props } from "./types";
import { FontSizes, FontWeight } from "Utils/enums";

const LoginTemplate: React.FC<Props> = (props: Props) => {
  const {
    children,
    onSubmit,
    title = "Welcome to DocuDent",
    subTitle = "Please enter your details to login",
    linkDescription = "Don't have an account ?",
    linkText = "Signup Now",
    link = "/sign-up",
    noForm,
    loading,
  } = props;

  const handleSubmit = (event: any) => {
    if(loading) return
    event.preventDefault();
    if (onSubmit) {
      onSubmit();
    }
  };

  const handleKeyPress = (event: {
    key?: any;
    preventDefault?: () => void;
  }) => {
    if (event.key === "Enter") {
      handleSubmit(event);
    }
  };

  return (
    <Container>
      <FormContainer>
        <img src={Logo} alt="login-banner" />
        {!noForm ? (
          <>
            <Label
              fontWeight={FontWeight.bold}
              marginBottom={2}
              color={colors.primaryText}
              fontSize={FontSizes.h1}
            >
              {title}
            </Label>
            <Label
              marginBottom={60}
              fontWeight={FontWeight.default}
              color={colors.secondaryText}
              fontSize={FontSizes.h6}
            >
              {subTitle}
            </Label>
            <form onSubmit={handleSubmit} onKeyPress={handleKeyPress}>
              {children}
            </form>
            <Label
              marginTop={80}
              fontSize={FontSizes.floatingLabel}
              color={colors.secondaryText}
            >
              {linkDescription}
              <NavLink to={link}> {linkText}</NavLink>{" "}
            </Label>
          </>
        ) : (
          children
        )}
      </FormContainer>
      <ImgBox>
        <Box>
          <Label
            fontWeight={FontWeight.light}
            style={{
              maxWidth: "60%",
              margin: "0 auto",
            }}
            color="white"
          >
            "Docudent enabled less data entry, more patient care"
          </Label>
          <img src={Banner} alt="login-banner" />
          <Label
            fontWeight={FontWeight.light}
            fontSize={FontSizes.small}
            color="white"
          >
            Copyright@docudent.ai 2024
          </Label>
        </Box>
      </ImgBox>
    </Container>
  );
};

export default LoginTemplate;
