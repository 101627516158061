import { colors } from 'Assets/Styles/colors';
import { Label } from 'Assets/Styles/globalStyles';
import { FontSizes } from 'Utils/enums';
import styled from "styled-components";

interface StyledComponentProps {
  marginTop?: number;
}

export const ErrorLabel = styled(Label)``

export const EyeBox = styled.div`
  right: 12px;
  top: 33px;
  font-size: 0.9rem;
  position: absolute;
  cursor: pointer;
  color: ${colors.primaryText}
`

export const Container = styled.div<StyledComponentProps>`
  text-align: left;
  margin-top: ${({ marginTop }) => (marginTop ? `${marginTop}px` : 0)};
  position: relative;

  .ant-select-selector, .ant-input {
    height: 40px !important;
    border-width: 1.5px !important;
    border-radius: 6px;
    font-size: ${FontSizes.input}rem !important;
    background-color: white !important;
  }

  .ant-select {
    width: 100% !important;
  }
`;