// Core
import { SubscriptionStatus } from "Utils/enums";
import React from "react";
import { Navigate, Outlet } from "react-router-dom";

// Others

const SubscribedRoute = (props: { status: string | null}) => {
  const { status } = props;

  if (status === SubscriptionStatus.Active) {
    return <Outlet />;
  } else if (status === 'expired') {
    return <Navigate to="/renew-subscription" />;
  } else {
    return <Navigate to="/profile-setup" />;
  }
};

export default SubscribedRoute;
