import React from "react";
import { Layout } from "antd";
import { Outlet } from "react-router-dom";
import SideBar from "Components/SideBar";
import ProfileHead from "Pages/Auth/SignUpSteps/ProfileHead";
import { Header } from "Assets/Styles/globalStyles";

const { Content, Sider } = Layout;

const SidebarLayout: React.FC = () => {
  return (
    <Layout hasSider>
      <Sider
        width={220}
        style={{
          overflow: "auto",
          height: "100vh",
          position: "fixed",
          left: 0,
          top: 0,
          bottom: 0,
        }}
      >
        <SideBar />
      </Sider>
      <Layout style={{ marginLeft: 220, minHeight: "100vh" }}>
        <Header>
          <ProfileHead />
        </Header>
        <Content>
          <Outlet />
        </Content>
      </Layout>
    </Layout>
  );
};

export default SidebarLayout;
