import styled from "styled-components";
import loginBottom from 'Assets/Images/login_bottom.svg';
import loginTop from 'Assets/Images/login_top.svg';

export const Container = styled.div`
    display: grid;
    grid-template-columns: 2fr 1.5fr;
    min-height: 100vh;
    width: 100vw;

    @media (max-width: 1000px) {
      grid-template-columns: 1fr;
    }
  `;

export const FormContainer = styled.div`
    padding: 70px 20px 20px 100px;
    max-width: 450px;

    @media (max-width: 1000px) {
      padding: 70px 50px;
      max-width: 100%;
    }

    img {
      max-width: 150px;
      margin-bottom: 70px;
    }
`;

export const ImgBox = styled.div`
  background: radial-gradient(50% 50% at 50% 50%, #02317D 0%, #01193F 100%),
  linear-gradient(0deg, #D9D9D9, #D9D9D9);
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  @media (max-width: 1000px) {
      padding: 70px 50px;
    }

  img {
    max-width: 65%;
    margin: 80px 0;
  }

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    background-image: url(${loginBottom});
    background-size: contain;
    background-repeat: no-repeat;
    background-position: bottom;
    width: 250px;
    height: 350px;
  }
  &::before {
    content: '';
    position: absolute;
    right: 0;
    top: 0;
    background-image: url(${loginTop});
    background-size: contain;
    background-repeat: no-repeat;
    background-position: right;
    height: 200px;
    width: 280px;
  }
  `;