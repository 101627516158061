// ErrorBoundary.tsx
import React from "react";
import { ErrorBoundary as ReactErrorBoundary } from "react-error-boundary";
import IMG from "Assets/Images/server.broke.svg";
import { InfoPageWrapper, Label } from "Assets/Styles/globalStyles";
import { FontSizes } from "Utils/enums";
import CustomButton from "Components/Button";

interface ErrorBoundaryProps {
  children: React.ReactNode;
}

const ErrorFallback: React.FC<{ error: Error }> = ({ error }) => {
  console.log("Reason to Break", error.message);
  return (
    <InfoPageWrapper>
      <img src={IMG} alt="img" />
      <Label marginTop={30} marginBottom={30} fontSize={FontSizes.h1}>Something Went Wrong</Label>
      <CustomButton
        onClickHandler={() => (window.location.href = '/patient')}
        width="130px"
        label="Go To Home"
      />
    </InfoPageWrapper>
  );
};

const ErrorBoundary: React.FC<ErrorBoundaryProps> = ({ children }) => {
  return (
    <ReactErrorBoundary FallbackComponent={ErrorFallback}>
      {children}
    </ReactErrorBoundary>
  );
};

export default ErrorBoundary;
