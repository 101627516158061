// Assuming USER_DETAILS is a string constant in 'config/actionTypes'
import { ActionTypes } from 'Utils/enums';
import initialState from './initialState';

// Reducer function
const globalStateReducer = (
  state: any = initialState,
  action: any
) => {
  switch (action.type) {
    case ActionTypes.PLAN_UPDATING:
      return {
        ...state,
        planUpdating: action.value,
      };
    case ActionTypes.AI_PROCESSING:
      return {
        ...state,
        aiImageProcessing: action.value,
      };

    default:
      return state;
  }
};

export default globalStateReducer;
