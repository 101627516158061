import { PLAN_TYPE } from "Utils/enums";
import { useSelector } from "react-redux";

export const useUserDetails = () => {
  const { userDetails } = useSelector<any, any>(
    (store) => store.userDetailsReducer
  );

  const isAdmin = userDetails?.currentOrg?.roles?.some((role: string) =>
    ["OrganizationOwner"].includes(role)
  );

  const isStaff =
    userDetails?.currentOrg?.roles?.length === 1 &&
    userDetails?.currentOrg?.roles[0] === "Staff";

  const isPaidPlan = userDetails?.isPaidPlan;
  const havePatientAccess =
    isPaidPlan &&
    userDetails?.currentOrg?.roles?.some((role: string) =>
      ["OrganizationOwner", "Doctor"].includes(role)
    );

  const haveCaseAccess =
    userDetails?.currentOrg?.roles?.some((role: string) =>
      ["OrganizationOwner", "Doctor"].includes(role)
    );

  const isOrgOwner =
    userDetails?.currentOrg?.roles?.length === 1 &&
    userDetails?.currentOrg?.roles[0] === "OrganizationOwner";

  return {
    userDetails,
    isAdmin,
    havePatientAccess,
    haveCaseAccess,
    isStaff,
    userLicenseRemaining:
      userDetails?.currentSubscription?.subscriptionDetails?.totalLicenseCount -
      userDetails?.currentSubscription?.activeOrgUsersCount,
    isUnlimitedPlan:
      userDetails?.currentSubscription?.subscriptionDetails?.planType ===
      PLAN_TYPE.UNLIMITED,
    isPaidPlan,
    currentSubscription: userDetails?.currentSubscription,
    isOrgOwner,
    personalInformation: userDetails?.personalInformation,
    userId: userDetails?._id,
    organizationId: userDetails?.organizationId,
  };
};
