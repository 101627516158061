import React from "react";
import { Props } from "./types";
import { TableWrapper } from "./styles";
import { Empty } from "antd";
import { Box } from "@rebass/grid";

const Table: React.FC<Props> = (props: Props) => {
  const {
    loading,
    columns,
    dataSource,
    onPageChange,
    totalCount,
    currentPage,
    noPagination,
    locale,
  } = props;

  const handlePageChange = (_page: number, _pageSize: number) => {
    onPageChange?.(_page);
  };

  const paginationConfig = {
    pageSize: 10,
    total: totalCount,
    current: currentPage,
    onChange: (_page: number, _pageSize: number) =>
      handlePageChange(_page, _pageSize),
  };

  return (
    <TableWrapper
      rowKey="_id"
      pagination={noPagination ? false : paginationConfig}
      loading={loading}
      dataSource={dataSource}
      columns={columns}
      locale={{
        emptyText: loading ? (
          <Box padding="50px"></Box>
        ) : (
          <Box padding={20}>
            <Empty description={locale || "No Data"} />
          </Box>
        ),
      }}
    />
  );
};

export default Table;
