// Assuming USER_DETAILS is a string constant in 'config/actionTypes'
import { ActionTypes } from 'Utils/enums';
import initialState from './initialState';

// Reducer function
const userDetailsReducer = (
  state: any = initialState,
  action: any
) => {
  switch (action.type) {
    case ActionTypes.USER_DETAILS:
      return {
        ...state,
        userDetails: action.value,
      };
    case ActionTypes.UPDATE_PERSONAL_INFORMATION:
      return {
        ...state,
        userDetails: {
          ...state.userDetails,
          personalInformation: {
            ...state.userDetails.personalInformation,
            ...action.value,
          },
        },
      };

    default:
      return state;
  }
};

export default userDetailsReducer;
