import React, { ChangeEvent, useState } from "react";
import Input from "antd/es/input";
import { EyeInvisibleOutlined, EyeOutlined } from "@ant-design/icons";
import { Form, FormItemProps } from "antd";
import AutoComplete from "antd/es/auto-complete";
import Spin from "antd/es/spin";
import { Flex } from "@rebass/grid";

// Style
import { Container, EyeBox } from "./styles";
import { Props } from "./types";
import { Label } from "Assets/Styles/globalStyles";
import { FontSizes, FontWeight } from "Utils/enums";
import { colors } from "Assets/Styles/colors";
import UserHead from "Components/UIBlocks/userHead";

const { Option } = AutoComplete;

const InputField: React.FC<Props & FormItemProps> = (
  props: Props & FormItemProps
) => {
  const {
    type,
    placeholder,
    onChange,
    value = "",
    label,
    error,
    onKeyDown,
    marginTop,
    isMandatory,
    noLabel,
    noError,
    disabled,
    isAutoComplete,
    options,
    optionLoading,
    name, // Ensure name is destructured from props
    ...restProps // Capture other FormItemProps
  } = props;

  const [showEye, setShowEye] = useState(false);

  const handleEyeClick = () => setShowEye((prev) => !prev);

  const handleSearch = (value: string) => {
    onChange?.(value);
  };

  const onSelect = (value: string) => {
    onChange?.(value);
  };

  const onChangeHandle = (e: ChangeEvent<HTMLInputElement>) =>
    onChange?.(e.target.value);

  return (
    <Form.Item
      name={name}
      // Pass FormItemProps to Form.Item
      help={noError ? null : error}
      validateStatus={error ? "error" : ""}
      {...restProps}
    >
      <Container {...{ marginTop }}>
        {label && !noLabel && (
          <Label
            marginBottom={5}
            fontSize={FontSizes.floatingLabel}
            color={colors.primaryText}
            fontWeight={FontWeight.default}
          >
            {label} {isMandatory && <span>*</span>}
          </Label>
        )}
        {isAutoComplete ? (
          <AutoComplete
            onSearch={handleSearch}
            onSelect={onSelect}
            value={value as string}
            disabled={disabled}
            placeholder={placeholder || label}
          >
            {optionLoading ? (
              <Option key="loading" value="loading">
                <Flex paddingTop="5px" justifyContent="center">
                  <Spin size="small" />
                </Flex>
              </Option>
            ) : (
              options?.map((option) => (
                <Option key={option.value} value={option.value}>
                  <UserHead
                    url={option.url}
                    label={option.label}
                    email={option.value}
                  />
                </Option>
              ))
            )}
          </AutoComplete>
        ) : (
          <Input
            disabled={disabled}
            autoComplete="off"
            value={value as string}
            placeholder={placeholder || label}
            onChange={onChangeHandle}
            type={showEye ? "text" : type || "text"}
            onKeyDown={onKeyDown}
          />
        )}
        {type === "password" && (
          <EyeBox onClick={handleEyeClick}>
            {showEye ? <EyeOutlined /> : <EyeInvisibleOutlined />}
          </EyeBox>
        )}
      </Container>
    </Form.Item>
  );
};

export default InputField;
