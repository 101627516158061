import React from "react";

// Styles
import { InfoPageWrapper, Label } from "Assets/Styles/globalStyles";

// Types
import { FontSizes } from "Utils/enums";
import CustomButton from "Components/Button";
import IMG from "Assets/Images/404.svg";
import { useNavigate } from "react-router-dom";
import { INITIAL_ROUTE } from "config/constants";


const PageNotFound: React.FC= () => {
  const navigate = useNavigate();
  return (
    <InfoPageWrapper>
      <img src={IMG} alt="img" />
      <Label marginTop={30} marginBottom={30} fontSize={FontSizes.h1}>Page Not Found</Label>
      <CustomButton
        onClickHandler={() => navigate(INITIAL_ROUTE)}
        width="130px"
        label="Go To Home"
      />
    </InfoPageWrapper>
  );
};

export default PageNotFound;
