import React from "react";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";

// Components
import Button from "Components/Button";
import UserHead from "Components/UIBlocks/userHead";

// Others
import { formatDate } from "Utils/helpers";
import { Flex } from "@rebass/grid";
import { colors } from "Assets/Styles/colors";
import { ColorMap, UserStatus } from "Utils/enums";
import Tag from "antd/es/tag";

export const officeOptions = [
  {
    label: "Administrator",
    value: "Admin",
    additionalText: "Dashboard, Calendar, Appointments, Reports",
  },
  {
    label: "Staff",
    value: "Staff",
    additionalText: "Dashboard, Calendar, Appointments, Reports",
  },
];

export const hcpOptions = [
  {
    label: "Full Access",
    value: "HCPWithFullAccess",
    additionalText: "Full access on patient details",
  },
  {
    label: "SSN Access",
    value: "HcpWithSsnViewAccess",
    additionalText: "Limited access on patient details such as SSN",
  },
  {
    label: "Only Case View",
    value: "HcpWithCaseViewAccess",
    additionalText: "Only View access on patient details",
  },
];

export const formFields = [
  {
    key: "email",
    label: "Email ID",
    type: "TF",
  },
  {
    key: "createdAt",
    label: "Created At",
    type: "DS",
  },
];

export const getFilteredData = (data: any, filter: any) => {
  const { searchQuery } = filter;
  const formattedSearchQuery = searchQuery?.toLowerCase().trim();

  return data?.filter((item: any) => {
    const itemEmail = (item.email || item.invitedEmail).toLowerCase();
    return !formattedSearchQuery || itemEmail?.includes(formattedSearchQuery);
  });
};

export const getColumns = (
  handleEditTeam: any,
  handleDelete: any,
  currentUserId: string,
  isAdmin: boolean,
  organizationId: string,
  context: string
) => [
  {
    title: `${context} Details`,
    dataIndex: "email",
    key: "email",
    show: true,
    render: (email: string, record: any) => (
      <UserHead
        url={record?.personalInformation?.profilePicKey}
        label={email || record?.invitedEmail}
        tag={currentUserId === record?._id ? "You" : ""}
      />
    ),
  },
  {
    title: "Role",
    dataIndex: "roles",
    key: "roles",
    render: (roles: string[], record: any) => {
      const currentOrgRoles = getCurrentOrgRoles(record, organizationId);
      const roleItem = roles || currentOrgRoles;
      const mapRole = (role: string) => {
        const roleMappings: Record<string, string> = {
          OrganizationOwner: "Organization Owner",
          HCPWithFullAccess: "Full Access",
          HcpWithSsnViewAccess: "SSN View Access",
          HcpWithCaseViewAccess: "Case View Access",
        };
        return roleMappings[role] || role;
      };
      if (Array.isArray(roleItem)) {
        const remappedRoles = roleItem.map(mapRole);
        return remappedRoles.join(", ");
      }
      return "";
    },
  },
  {
    title: "Created At",
    width: 150,
    dataIndex: "createdAt",
    key: "createdAt",
    render: (createdAt: string) => (createdAt ? formatDate(createdAt) : ""),
  },
  {
    title: "Status",
    dataIndex: "status",
    key: "status",
    render: (status: keyof typeof UserStatus) => {
      const _status = status || "OnboardingComplete";
      return <Tag color={ColorMap[_status]}>{UserStatus[_status]}</Tag>;
    },
  },
  {
    title: "Actions",
    dataIndex: "_id",
    key: "_id",
    hidden: !isAdmin,
    width: 150,
    render: (_id: string, record: any) => {
      if (currentUserId === _id) return <></>;
      const currentOrgRoles = getCurrentOrgRoles(record, organizationId);
      const showEdit = !["EmailVerified", "Invited"].includes(record.status);
      if (showEdit && currentOrgRoles.includes("OrganizationOwner")) {
        return <></>;
      }
      return (
        <Flex style={{ gap: "6px" }}>
          {showEdit && (
            <Button
              type="dashed"
              onClickHandler={handleEditTeam(record)}
              icon={<EditOutlined />}
              size="small"
            />
          )}
          <Button
            type="dashed"
            onClickHandler={handleDelete(record)}
            icon={<DeleteOutlined style={{ color: colors.error }} />}
            size="small"
          />
        </Flex>
      );
    },
  },
];

export const getCurrentOrgRoles = (data: any, organizationId: string) => {
  const currentOrg = data?.organizations?.find(
    (item: any) => item.organizationId === organizationId
  );
  return currentOrg?.roles || [];
};
