// Core
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Auth } from "aws-amplify";
import { CheckCircleOutlined, SyncOutlined } from "@ant-design/icons";
import { Box } from "@rebass/grid";

// Components
import LoginTemplate from "Components/LoginTemplate";
import Button from "Components/Button";
import Loader from "Components/Loader";

// Styles
import { colors } from "Assets/Styles/colors";
import { Label } from "Assets/Styles/globalStyles";

// Types
import { FontSizes, FontWeight } from "Utils/enums";

// Others
import { fetchData } from "Utils/fetch";
import { restAPIs } from "Utils/restAPIs";

const Invite: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [inviteCheck, setInviteCheck] = useState(true);
  const queryParams = new URLSearchParams(location.search);
  const inviteCode = queryParams.get("invite_code");

  const checkInvite = async () => {
    if (!inviteCode) return;
    setLoading(true);
    setInviteCheck(true);
    const res = await fetchData(restAPIs.checkInvite(inviteCode as string));
    if (res?.data) {
      await Auth.signOut();
      const { isExistingUser: _isExistingUser, invitedEmail } = res.data;
      if (_isExistingUser) {
        setInviteCheck(false);
        const inviteRes = await fetchData(
          restAPIs.acceptInvite(inviteCode as string)
        );
        if (!inviteRes) {
          handleRedirect();
        }
      } else {
        localStorage.setItem("inviteCode", inviteCode);
        localStorage.setItem("invitedEmail", invitedEmail);
        navigate("/sign-up");
      }
    } else {
      await Auth.signOut();
      navigate("/login");
    }
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  };

  const handleRedirect = () => {
    navigate("/login");
  };

  useEffect(() => {
    checkInvite();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (inviteCheck) return <Loader size="large" fullscreen />;

  return (
    <LoginTemplate loading={loading} noForm>
      <Box>
        {loading ? (
          <SyncOutlined
            spin
            style={{ color: colors.secondaryText, fontSize: "3rem" }}
          />
        ) : (
          <CheckCircleOutlined
            style={{ color: colors.success, fontSize: "3rem" }}
          />
        )}

        <Label
          fontWeight={FontWeight.bold}
          marginBottom={2}
          marginTop={20}
          color={colors.primaryText}
          fontSize={FontSizes.h1}
        >
          {loading ? "Please Wait!" : "Invitation Accepted"}
        </Label>
        {!loading && (
          <>
            <Label
              marginBottom={60}
              fontWeight={FontWeight.default}
              color={colors.secondaryText}
              fontSize={FontSizes.h6}
            >
              You have successfully accepted the invitation to join this
              organization. login now to start collaborating !
            </Label>
            <Button
              onClickHandler={handleRedirect}
              marginTop={20}
              width="150px"
              label="Login to Continue"
            />
          </>
        )}
      </Box>
    </LoginTemplate>
  );
};

export default Invite;
