import { colors } from 'Assets/Styles/colors';
import { Label } from 'Assets/Styles/globalStyles';
import { FontSizes } from 'Utils/enums';
import Button from 'antd/es/button';
import styled, { css } from "styled-components";

interface StyledComponentProps {
  marginTop?: number;
  marginBottom?: number;
  backgroundColor?: string;
  background?: string;
  width?: string;
  type?: string;
}

export const StyledButton = styled(Button) <StyledComponentProps>`
  margin-top: ${({ marginTop }) => (marginTop ? `${marginTop}px` : 0)};
  margin-bottom: ${({ marginBottom }) => (marginBottom ? `${marginBottom}px` : 0)};
  border-radius: 6px;
  font-size: ${FontSizes.button}rem;
  padding: 8px 15px;
  height: auto;
  color: white;
  background-color: ${({ backgroundColor }) => backgroundColor || colors.primary};
  background: ${(props) => props.background};
  width: ${({ width }) => width || 'auto'};

  ${(props) =>
    props.background &&
    css`
      &:hover {
        filter: brightness(1.1);
        background: ${props.background} !important;
      }
    `}

  &.ant-btn-primary:disabled {
      background-color: ${colors.primary};
      opacity: 0.7;
      color: white;
    }

  ${Label} {
    display: inline-block !important;
    cursor: pointer;
  }

  .ant-tag {
    margin: 0;
    margin-left: 2px;
    font-size: ${FontSizes.error}rem;
    border-radius: 50%;
    height: 16px;
    width: 16px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }

  &.icon-btn {
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;

    .anticon {
      font-size: ${FontSizes.btnIcon}rem !important;
    }
  }
`;