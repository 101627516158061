// Core
import { INITIAL_ROUTE } from 'config/constants';
import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';

// Others

const ProtectedRoute = (props: {isAuthenticated: boolean}) => {

  const { isAuthenticated } = props

  return isAuthenticated ? <Navigate to={INITIAL_ROUTE} /> : <Outlet /> ;
};
export default ProtectedRoute;
