// Core
import React from "react";
import { Navigate, Outlet } from "react-router-dom";

// Others

const PrivateRoute = (props: { isAuthenticated: boolean }) => {
  const { isAuthenticated } = props;
  return isAuthenticated ? <Outlet /> : <Navigate to="/login" />;
};
export default PrivateRoute;
