import {
  UsergroupAddOutlined,
  // BlockOutlined,
  SettingOutlined,
  // ScheduleOutlined,
  BankOutlined,
} from "@ant-design/icons";

export const sideBarMenu = [
  // {
  //   icon: <BlockOutlined  />,
  //   label: "Dashboard",
  //   path: "/",
  // },
  // {
  //   icon: <CalendarOutlined />,
  //   label: "Calendar",
  //   path: "/calendar",
  //   activeFor: 'calendars',
  // },
  // {
  //   icon: <ScheduleOutlined />,
  //   label: "Appointments",
  //   activeFor: '/appointments',
  //   path: "/appointments",
  // },
  {
    icon: <UsergroupAddOutlined />,
    label: "Patients",
    activeFor: '/patient',
    path: "/patient",
  },
  // {
  //   icon: <FundProjectionScreenOutlined />,
  //   label: "Reports",
  //   activeFor: '/reports',
  //   path: "/reports",
  // },
  {
    icon: <BankOutlined />,
    label: "My Office",
    activeFor: '/my-office',
    path: "/my-office",
  },
  {
    icon: <SettingOutlined />,
    label: "Settings",
    activeFor: '/settings',
    path: "/settings",
  },
];
