import { AccountDetailsType } from "Pages/Auth/SignUpSteps/types";

export const restAPIs = {
  getLoggedUser: {
    method: "get",
    endpoint: `users/me`,
  },
  getProductList: {
    method: "get",
    endpoint: 'payment/list-products'
  },
  getCheckoutSession: (priceId: string, quantity: number) => ({
    method: "get",
    endpoint: `payment/${priceId}/create-checkout-session?quantity=${quantity}`
  }),
  getSubscriptionDetails: (id: string) => ({
    method: "get",
    endpoint: `payment/${id}`
  }),
  getOrganizationDetails: (organizationId: string, successToast?: string) => ({
    method: "get",
    endpoint: `organizations/${organizationId}`,
    successToast
  }),
  getInviteList: (organizationId: string) => ({
    method: "get",
    endpoint: `invite/organization/${organizationId}`
  }),
  getPatientMetrics: (patientId: string) => ({
    method: "get",
    endpoint: `case/patient/${patientId}/metrics`
  }),
  getCaseList: (patientId: string) => ({
    method: "get",
    endpoint: `case/patient/${patientId}`
  }),
  getHcpInviteList: (id: string) => ({
    method: "get",
    endpoint: `invite/patient/${id}`
  }),
  getUserList: (organizationId: string) => ({
    method: "get",
    endpoint: `users/organization/${organizationId}`
  }),
  getHcpList: (id: string) => ({
    method: "get",
    endpoint: `patient/${id}/hcp-details`
  }),
  setPersonalInformation: (userId: string, formData: AccountDetailsType) => ({
    method: "patch",
    body: formData,
    successToast: "Your changes are saved successfully",
    endpoint: `users/${userId}/personal-information`
  }),
  addOrEditPatient: (id: string, body: any, isEdit?: boolean) => ({
    method: isEdit ? "patch" : "post",
    body,
    successToast: isEdit ? "Patient Updated Successfully." : "New Patient Added",
    endpoint: `patient/${id}`
  }),
  addPatientPicture: (type: string) => ({
    method: "get",
    endpoint: `patient/profilepic/upload/${type}`
  }),
  uploadCaseDocument: (caseId: string, category: string, type: string) => ({
    method: "get",
    endpoint: `case/${caseId}/${category}/upload/${type}`
  }),
  addUserPicture: (type: string) => ({
    method: "get",
    endpoint: `users/profilepic/upload/${type}`
  }),
  s3FileUpload: (url: string, body: any, fileExtension?: string) => ({
    method: "put",
    endpoint: url,
    body,
    withoutToken: true,
    fullUrl: true,
    contentType: fileExtension
  }),
  deletePatient: (id: string, organizationId: string) => ({
    method: "delete",
    endpoint: `patient/${id}/organization/${organizationId}`,
    context: 'Patient',
    successToast: "Patient Deleted Successfully.",
  }),
  deleteCase: (caseId: string) => ({
    method: "delete",
    endpoint: `case/${caseId}`,
    context: 'Case'
  }),
  deleteTeam: (id: string, organizationId: string) => ({
    method: "patch",
    action: "delete",
    endpoint: `users/${id}/organization/${organizationId}/remove`,
    context: 'Member'
  }),
  updateCaseStatus: (caseId: string, status: string) => ({
    method: "patch",
    body: { status },
    endpoint: `case/${caseId}/status`,
    successToast: status === "completed" ? "Case Marked as Complete" : "Case Marked as Open"
  }),
  deleteHcp: (id: string, hcpId: string) => ({
    method: "delete",
    endpoint: `patient/${id}/hcp/${hcpId}`,
    context: 'HCP'
  }),
  deleteInvite: (id: string) => ({
    method: "delete",
    endpoint: `invite/${id}`,
    context: 'Invite'
  }),
  removeCollaborator: (caseId: string, collaboratorId: string) => ({
    method: "delete",
    endpoint: `case/${caseId}/collaborator/${collaboratorId}`,
    context: 'Collaborator'
  }),
  getPatientDetails: (id: string | undefined) => ({
    method: "get",
    endpoint: `patient/${id}`,
  }),
  getPatientCaseHistory: (id: string) => ({
    method: "get",
    endpoint: `case/patient/${id}/history`,
  }),
  getPatientCaseTimelines: (patientId: string) => ({
    method: "get",
    endpoint: `case/patient/${patientId}/timeline-history`,
  }),
  checkSSNDuplicate: (organizationId: string, ssn: string) => ({
    method: "get",
    endpoint: `patient/${organizationId}/ssn/${ssn}`,
  }),
  getCaseDetails: (caseId: string) => ({
    method: "get",
    endpoint: `case/${caseId}`,
  }),
  getCollaboratorsList: (caseId: string) => ({
    method: "get",
    endpoint: `case/${caseId}/collaborators`,
  }),
  getCaseCollaborator: (caseId: string) => ({
    method: "get",
    endpoint: `case/${caseId}/case-collaborator`,
  }),
  getAllCaseNotes: (caseId: string) => ({
    method: "get",
    endpoint: `case/${caseId}/notes`,
  }),
  getCaseTypeList: {
    method: "get",
    endpoint: `case/constants`,
  },
  checkInvite: (inviteCode: string) => ({
    method: "get",
    endpoint: `invite/check/${inviteCode}`,
    withoutToken: true,
  }),
  acceptInvite: (inviteCode: string) => ({
    method: "patch",
    endpoint: `invite/${inviteCode}/accept`,
    withoutToken: true,
  }),
  getPatientList: (body: any) => ({
    method: "post",
    body,
    endpoint: `patient/search`
  }),
  getCaseTable: (body: any, patientId: string) => ({
    method: "post",
    endpoint: `case/patient/${patientId}/search`,
    body,
  }),
  getCreateCaseStatus: (patientId: string) => ({
    method: "get",
    endpoint: `patient/${patientId}/can-requester-create-case`,
  }),
  getOrgUsers: (body: any) => ({
    method: "post",
    body,
    endpoint: `users/search`
  }),
  updateStripeCard: (body: any, organizationId: string) => ({
    method: "post",
    body,
    endpoint: `payment/${organizationId}/update-card`,
    successToast: "New Card added Successfully.",
  }),
  updateSubscription: (body: any, organizationId: string) => ({
    method: "post",
    body,
    endpoint: `payment/${organizationId}/update-subscription`,
  }),
  inviteUser: (body: any, organizationId: string) => ({
    method: "post",
    body,
    endpoint: `invite/${organizationId}/email`
  }),
  inviteHCP: (body: any, organizationId: string, id: string) => ({
    method: "post",
    body,
    endpoint: `invite/${organizationId}/patient/${id}/hcp`
  }),
  createCase: (patientId: string, organizationId: string) => ({
    method: "post",
    body: {
      organizationId
    },
    endpoint: `case/patient/${patientId}`,
    successToast: "New Case Created Successfully.",
  }),
  updateTeethOperation: (caseId: string, body: any, showToast?: boolean) => ({
    method: "patch",
    body,
    endpoint: `case/${caseId}/teeth-operation`,
    successToast: showToast ? "Case Teeth Operation Updated Successfully." : "",
  }),
  updateCaseType: (caseId: string, body: any) => ({
    method: "patch",
    body,
    endpoint: `case/${caseId}/case-type`,
    successToast: "Case Type Updated Successfully.",
  }),
  updateCaseDetails: (caseId: string, body: any) => ({
    method: "patch",
    body,
    endpoint: `case/${caseId}/case-details`,
    successToast: "Case Details Updated Successfully.",
  }),
  createCaseAdditionalNotes: (caseId: string, body: any) => ({
    method: "post",
    body,
    endpoint: `case/${caseId}/case-details/additional-notes`,
    successToast: "Note Added Successfully.",
  }),
  editCaseAdditionalNotes: (caseId: string, id: string, body: any) => ({
    method: "patch",
    body,
    endpoint: `case/${caseId}/case-details/additional-notes/${id}`,
    successToast: "Note Updated Successfully.",
  }),
  deleteCaseAdditionalNotes: (caseId: string, id: string) => ({
    method: "delete",
    endpoint: `case/${caseId}/case-details/additional-notes/${id}`,
    context: 'Note',
    successToast: "Note Deleted Successfully.",
  }),
  getCaseFileDownloadUrl: (caseId: string, body: any) => ({
    method: "post",
    body,
    endpoint: `case/${caseId}/download`
  }),
  updateCollaborator: (caseId: string, body: any, organizationId: string) => ({
    method: "post",
    body,
    endpoint: `invite/${organizationId}/case/${caseId}/collaborators`,
    successToast: "Case Collaborators Updated Successfully.",
  }),
  updateUserRoles: (body: any, id: string, organizationId: string) => ({
    method: "patch",
    body,
    endpoint: `users/${id}/organization/${organizationId}/roles`
  }),
  updateHcpRoles: (body: any, id: string, hcpId: string) => ({
    method: "patch",
    body,
    endpoint: `patient/${id}/hcp/${hcpId}/roles`
  }),
  getTimeline: (caseId: string) => ({
    method: "get",
    endpoint: `case/${caseId}/timeline`,
  }),
  updateTimeline: (caseId: string, body: any) => ({
    method: "put",
    body,
    endpoint: `case/${caseId}/timeline`,
    successToast: "Case Timeline Updated Successfully.",
  }),
  patchCaseComponents: (caseId: string, body: any) => ({
    method: "patch",
    body,
    endpoint: `case/${caseId}/components`
  }),
  getPresignedUrlComponentUpload: (caseId: string, type: string, category: "timeline" | "case-details") => ({
    method: "get",
    endpoint: `case/${caseId}/component/upload/${type}`
  }),
  analyseComponent: (caseId: string, s3Key: string) => ({
    method: "post",
    endpoint: `case/${caseId}/component/analyze-image`,
    body: { s3Key },
  }),
  getManufacturerList: {
    method: "get",
    endpoint: `case/components/manufactures/search`,
  },
};
