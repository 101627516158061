// Core
import { combineReducers } from 'redux';

/// Others
import userDetailsReducer from './userDetails.reducer';
import globalStateReducer from './globalState.reducer';

const rootReducer: any = combineReducers({
  userDetailsReducer,
  globalStateReducer
});
export default rootReducer;
